.horizontal-tree {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /*justify-content: center;*/
}

.branch {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -10px;
  margin-bottom: -10px;
}

.node {
  height: 50px;
  display: flex;
  align-items: center;
}

.connector {
  height: 50px;
  font-size: 30px;
  padding: 1px 0;
}
