@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  font-size: 15px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: "SUIT";
  src: url(./assets/fonts/SUIT-Variable.ttf);
}

@font-face {
  font-family: "Lexend";
  src: url(./assets/fonts/Lexend-VariableFont_wght.ttf);
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(./assets/fonts/NotoSansKR.ttf);
}
